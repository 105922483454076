import {
    call, put, select, getContext,
    delay,
} from 'redux-saga/effects';
import lodashGet from 'lodash/get';
import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';
import { CryptoUtil } from '@airtel-tv/utils/CryptoUtil';
import { MODAL_POPUP } from '@airtel-tv/constants';
import AuthenticationUtil from '@airtel-tv/utils/AuthenticationUtil';
import CacheUtil from '@airtel-tv/utils/CacheUtil';
import { regOtpResponseEvent, ssoFailedEvent, loginCompleteEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { Analytics } from '@airtel-tv/analytics';
import { playbackClearAllAction } from '@airtel-feature/playback/actions/PlaybackActions';
import { syncContentClear } from '@airtel-tv/sync/SyncActions';
import { syncContentSaga } from '@airtel-tv/sync/SyncSaga';
import { Crash } from '@airtel-tv/crash-capture';
import { getAuthConfigFromReducer } from '@airtel-tv/redux/StoreListing';
import { layoutReset, purgeLayoutData } from '@airtel-feature/layout/actions/LayoutActions';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
import LocationUtil from '@airtel-tv/utils/LocationUtil';
import { takeFirst } from '../../utilities/ReduxSagaUtil';
import {
    deviceSessionOutSuccess,
    setAutoLoginStatus,
} from '@airtel-feature/auth/AuthAction';

import { showToastr } from '@airtel-tv/utils/NotifyUtil';
import ScopedWebviewUtil from '@airtel-tv/utils/ScopedWebviewUtil';
import { getWindowLocation } from '@airtel-tv/utils';
import AuthAction, {
    deleteAccountOtpError,
    tempLoginRequestSuccess,
    logoutRequestSuccess,
    generateHappyCodeSuccess,
    triggerLogin,
    isUserOnline,
    resetMsisdn,
    updateTvLoginStatus,
} from './AuthAction';
import {
    generateOtpCall, getTempLoginToken, validateOtpCall, validateDeleteAccountOtpCall, deleteAccountCall, disposeAuthSession, dthRechargeRedirection, happyCodeGeneration,
    validateSSOToken, autoLogin,
} from '../../service/end-points/AuthApiService';
import UserAction, { userConfigSuccessAction, activatePartnerSubscription, updateTempUserLang } from '../user/UserAction';
import { CACHE_CONFIGS } from '../../constants/AppConst';
import { hideLoadingBar, showLoadingBar, redirectTo } from '../notify/NotifyActions';
import { isOnline, getLocationPathName } from '../../utilities/WindowUtil';
import { getAppId } from '../../utilities/CommonUtil';
import { successfulLoginGaEvent } from '../../utilities/GaEvents';
import UserStorageUtil from '../user/UserStorageUtil';
import UserInfoUtil from '../../utilities/UserInfoUtil';
import { getUserConfig } from '../../service/end-points/ConfigApiService';
import { PopupConfigFetchAction } from '../preferred-partner/PopupActions';
import BranchioUtil from '../../utilities/BranchioUtil';
import ErrorHandler from '../error-handler/ErrorHandler';
import { getCombinedPlansInit, subscriptionClearAll } from '../subscription-details/action/SubscriptionAction';
import { planConfigClearAll, planConfigInit } from '../plan/PlanActions';
import { hideModalComponentAction, showModalComponentAction } from '../modal-popup/ModalPopupActions';

function* clearAuthDataFromLocal() {
    AuthenticationUtil.clearAuthToken();
    AuthenticationUtil.clearAccessToken();
    AuthenticationUtil.clearUid();
    AuthenticationUtil.clearMsisdnDetected();
    // refresh user config in cache
    yield CacheUtil.clearByName(CACHE_CONFIGS.CRITICAL_PRIORITY_CACHE);
}

function* generateOtp(action) {
    try {
        yield put(showLoadingBar());

        const authConfig = yield select(getAuthConfigFromReducer);
        const deviceUtil = yield getContext('deviceUtil');
        const appId = getAppId();
        const xAtvDid = deviceUtil.getXAtvDid();

        const data = yield call(generateOtpCall, action.msisdn, authConfig.token, authConfig.uid, appId, xAtvDid, action.viaCall);

        yield put({
            type: AuthAction.LOGIN_OTP_GENERATE_SUCCESS,
            msisdn: action.msisdn,
            message: data.message,
            success: data.success,
        });
    }
    catch (e) {
        yield put({
            type: AuthAction.ON_LOGIN_ERROR,
            error: e.data ? e.data : e,
        });
    }
    finally {
        yield put(hideLoadingBar());
    }
}

function* validateTvLogin(action) {
    const { otp, qrLoginPayload = {}, isMultiDeviceLogin = false } = action;
    try {
        const deviceUtil = yield getContext('deviceUtil');
        yield put(showLoadingBar());
        const user = yield select(getAuthConfigFromReducer);
        const params = {
            msisdn: user.msisdn,
            otp,
            token: user.token,
            uid: user.uid,
            appId: getAppId(),
            xAtvDid: deviceUtil.getXAtvDid(),
            isMultiDeviceLogin,
            qrLoginPayload,
        };
        const data = yield call(validateOtpCall, params);
        if (isMultiDeviceLogin) {
            yield put(updateTvLoginStatus(data));
        }
    }
    catch (err) {
        let { data } = err;
        if (!data) {
            data = {
                errorTitle: 'Unknown error occurred',
            };
        }
        else if (!data.errorTitle) {
            data.errorTitle = 'Invalid code, try again';
        }
        yield put(updateTvLoginStatus(data));
    }
}

function* validateOtp(action) {
    const {
        otp, redirectUrl, qrLoginPayload = {}, isMultiDeviceLogin = false,
    } = action;

    try {
        const deviceUtil = yield getContext('deviceUtil');
        yield put(showLoadingBar());
        const user = yield select(getAuthConfigFromReducer);
        const params = {
            msisdn: user.msisdn,
            otp,
            token: user.token,
            uid: user.uid,
            appId: getAppId(),
            xAtvDid: deviceUtil.getXAtvDid(),
            isMultiDeviceLogin,
            qrLoginPayload,
        };
        const data = yield call(validateOtpCall, params);

        yield put({
            type: AuthAction.LOGIN_OTP_VALIDATION_SUCCESS,
            ...data,
        });

        if (!data.success && data?.deviceResponse?.devices?.length) {
            // save devices info
            // AuthenticationUtil.setLoginDeviceSuccess(data.success);
            // AuthenticationUtil.setLoginDeviceResponse(data.deviceResponse);
            // call modalpopup
            yield put(showModalComponentAction({
                showModalValue: true,
                componentNameValue: MODAL_POPUP.DEVICE_LIMIT_REACHED,
                overrideCrossButtonValue: true,
                payload: {
                    popUpcustomClass: 'second-pop-up',
                },
            }));
            return;
        }
        // reset layout first then update user config so that user config update will actually update the ui :: reseting layout wont call update as layout container is not listening to whole reducer

        let userLang = lodashGet(data, 'userConfig.userInfo.lang', []);
        if (!userLang || userLang.length < 1) {
            userLang = lodashGet(data, 'userConfig.userConfigResponse.ln', '').split(',');
        }
        UserInfoUtil.setUserLanguage(userLang);

        // // set user config
        yield put({
            type: UserAction.USER_CONFIG_SUCCESS,
            userConfig: {
                ...data.userConfig,
                userInfo: {
                    lang: userLang,
                },
            },
        });

        const redirectionUrl = redirectUrl;

        const { uid, token } = { ...data };

        yield call(syncContentSaga, {
            forceSync: false,
            playbackForced: true,
            uid,
            token,
        });

        yield put({
            type: AuthAction.LOGIN_OTP_VALIDATION_SUCCESS,
            ...data,
        });

        // yield put(syncContentInit(
        //     {
        //         forceSync: true,
        //         isDelay: false,
        //     },
        // ));

        yield put(PopupConfigFetchAction({}));
        yield put(planConfigInit());
        yield put(getCombinedPlansInit());
        let userData;
        try {
            userData = yield call(getUserConfig, (deviceUtil));
        }
        catch (err) {
            const res = yield call(() => import('../../../server/mockResponses/tempLogin.json'));
            userData = res.default;
        }
        const {
            userInfo: {
                msisdn = '',
            } = {},
        } = userData;
        let msisdnNo = '';
        if (msisdn && token) {
            msisdnNo = CryptoUtil.AESDecryptMsdin({
                key: token,
                value: msisdn,
            });
        }
        const { experimentDetails } = userData;
        Analytics.initialize({
            deviceUtil,
            experimentDetails,
        });
        BranchioUtil.setBranchIdentity(userData?.userInfo?.uid);
        yield put(userConfigSuccessAction({
            userConfig: {
                ...userData,
                msisdnNo,
            },
        }));
        const { activationId, sessionId } = user;
        if (user && activationId && sessionId) {
            yield put(activatePartnerSubscription({
                activationId,
                sessionId,
            }));
        }
        yield put(purgeLayoutData());

        if (redirectionUrl && !redirectionUrl.includes('NO_REDIRECT')) {
            yield put(redirectTo({ redirectUrl: redirectionUrl }));
        }

        // refersh user config in cache
        // TODO: find a solution
        // commented by Ashutosh - this is causing bug in safari 11.0, unable to set localStorage item msisdnDetected
        // yield CacheUtil.clearByName(CACHE_CONFIGS.CRITICAL_PRIORITY_CACHE);

        // TODO: ask ashu why getting user config again when login call is getting user config
        // yield put({
        //     type: UserAction.USER_CONFIG_INIT,
        // });

        Crash.initialize(deviceUtil);
        // if (data.userConfig) {
        // change user language
        // const { lg } = data.userConfig.userProperties || LANGUAGES.ENGLISH;
        // getLanguageFile({ languageCode: lg }).then(languages => LanguageProvider(languages));
        // }
        regOtpResponseEvent('success');
        const programTypeTitle = getLocationPathName().split('/')[1] || '';
        successfulLoginGaEvent({
            programTypeTitle,
        });
        const loginCount = UserInfoUtil.getSuccessfulLoginCount() + 1;
        UserInfoUtil.setSuccessfulLoginCount({ loginCount });
        UserStorageUtil.clearTempLang();
        yield put(isUserOnline()); // to reset error in authConfig if any
        // successfulLoginCountGaEvent(loginCount);
    }
    catch (e) {
        yield put({
            type: AuthAction.ON_LOGIN_ERROR,
            error: e.data ? e.data : e,
        });
        AuthenticationUtil.clearMsisdnDetected();
        regOtpResponseEvent('failure');
    }
    finally {
        yield put(hideLoadingBar());
    }
}

function* deleteAccount() {
    try {
        const deviceUtil = yield getContext('deviceUtil');
        yield put(showLoadingBar());
        const user = yield select(getAuthConfigFromReducer);
        const data = yield call(deleteAccountCall, user.token, user.sessionToken, user.activeUid, getAppId(), deviceUtil.getXAtvDid());
        if (data && data.success) {
            try {
                // screenClosedEvent({
                //     id: 'DELETE_ACCOUNT_FIRST_SCREEN',
                //     screen_id: 'DELETE_ACCOUNT_FIRST_SCREEN',
                // });
                window.AirtelTVClient.logout();
            }
            catch (e) {
                window.webkit.messageHandlers.logout.postMessage(JSON.stringify({}));
            }
            finally {
                LocationUtil.handleCloseAppstate();
            }
        }
        // yield put({
        //     type: AuthAction.VALIDATE_DELETE_ACCOUNT_SUCCESS,
        //     ...data,
        // });
    }
    catch (error) {
        const payload = lodashGet(error, 'data', error);
        ErrorHandler({
            code: payload.errorcode,
            errortitle: payload.errortitle,
        });
    }
}

function* validateDeleteAccountOtp(action) {
    const { otp, username } = action;
    try {
        const deviceUtil = yield getContext('deviceUtil');
        yield put(showLoadingBar());
        const user = yield select(getAuthConfigFromReducer);
        const data = yield call(validateDeleteAccountOtpCall, username, otp, user.token, user.uid, getAppId(), deviceUtil.getXAtvDid());
        yield put({
            type: AuthAction.VALIDATE_DELETE_OTP_SUCCESS,
            ...data,
        });
        yield call(deleteAccount);
    }
    catch (error) {
        const payload = lodashGet(error, 'data', error);
        // ErrorHandler({
        //     code: payload.errorcode,
        //     errortitle: payload.errortitle,
        // });
        const LANGUAGE = LanguageProviderUtil.getLanguage();

        yield put(deleteAccountOtpError({
            otpError: {
                code: payload.errorcode,
                message: LANGUAGE[payload.errorcode] || payload.errortitle,
            },
        }));
    }
}


function* loginGetToken(action) {
    try {
        const deviceUtil = yield getContext('deviceUtil');
        const authConfig = yield select(getAuthConfigFromReducer);

        const { token, uid } = authConfig;

        if (!token || !uid) {
            const response = yield call(getTempLoginToken, deviceUtil, getAppId());
            yield put({
                type: AuthAction.LOGIN_REQUEST_SUCCESS,
                ...response,
            });
        }

        if (action.msisdn) {
            yield put({
                type: AuthAction.LOGIN_OTP_GENERATE_INIT,
                msisdn: action.msisdn,
            });
        }
    }
    catch (e) {
        let error = e;

        if (!isOnline()) {
            error = {
                data: { errorcode: ERROR_CODES.LOCAL1003 },
            };
        }

        yield put({
            type: AuthAction.ON_LOGIN_ERROR,
            error: error.data ? error.data : error,
        });
        AuthenticationUtil.clearMsisdnDetected();
        regOtpResponseEvent('failure');
    }
}

function* deviceSessionOut(action) {
    try {
        const { deviceId, triggerLogin: loginAfterLogout = false } = action;
        const authInfo = yield select(getAuthConfigFromReducer);
        const deviceUtil = yield getContext('deviceUtil');
        // sync continue watching data
        // yield call(syncContentSaga, {
        //     forceSync: true,
        //     isDelay: false,
        // });
        // disposing the token on server
        yield call(disposeAuthSession, {
            uid: authInfo.uid,
            token: authInfo.token,
            deviceUtil,
            deviceId,
        });
        // const { deviceResponse: { devices = [] } } = authInfo;
        // const deviceArr = devices.filter(item => item.deviceId !== deviceId);
        yield put(deviceSessionOutSuccess({
            success: true,
            deviceResponse: {},
        }));
        yield clearAuthDataFromLocal();
        yield put(logoutRequestSuccess());
        yield put(hideModalComponentAction({
            showModalValue: false,
            componentNameValue: MODAL_POPUP.DEVICE_LIMIT_REACHED,
            overrideCrossButtonValue: null,
        }));
        if (loginAfterLogout) {
            yield put(isUserOnline());
            yield put(resetMsisdn());
            yield put(triggerLogin({ loginTriggered: true }));
        }
    }

    catch (e) {
        // need other handling, toast?
        console.error(e);
        let error = e;
        if (!isOnline()) {
            error = {
                data: { errorcode: ERROR_CODES.LOCAL1003 },
            };
        }
        ErrorHandler({
            code: error?.data?.errorcode,
            errortitle: error?.data?.errortitle,
        });
    }
}

function* logoutUser(action) {
    try {
        const { redirectUrl = null, triggerLogin: loginAfterLogout = false, deviceId } = action;
        const authInfo = yield select(getAuthConfigFromReducer);
        const deviceUtil = yield getContext('deviceUtil');
        // sync continue watching data
        yield call(syncContentSaga, {
            forceSync: true,
            isDelay: false,
        });
        // disposing the token on server
        yield call(disposeAuthSession, {
            uid: authInfo.uid,
            token: authInfo.token,
            deviceUtil,
            deviceId,
        });

        // CLEAR LOCAL STORAGE DATA
        yield clearAuthDataFromLocal();

        // *** clear playback data
        yield put(playbackClearAllAction());

        yield put(planConfigClearAll());

        yield put(subscriptionClearAll());

        // yield put(layoutReset()); // user logged out layout changed

        // *** HERE IS YOUR LOGOUT STARTED
        yield put({
            type: AuthAction.LOGOUT_REQUEST,
        });

        // clear sync data
        yield put(syncContentClear({ clearLocal: false }));

        // *** RE-LOGIN FOR A TEMP TOKEN
        const loginResponse = yield call(getTempLoginToken, deviceUtil, getAppId());
        const tempLangFromResponse = lodashGet(loginResponse, 'userConfig.userContentProperties.ln', '');
        UserInfoUtil.setUserLanguage(tempLangFromResponse.split(','));

        yield put(tempLoginRequestSuccess(loginResponse));
        const payload = {
            ln: UserInfoUtil.getUserLanguage(),
        };
        yield put(updateTempUserLang(payload));
        yield put(purgeLayoutData());

        BranchioUtil.logoutBranch();
        BranchioUtil.setBranchIdentity('userNotLoggedIn');
        // *** GET USER CONFIG
        // *** RESET LAYOUT
        // *** SET NEW USER CONFIG
        // yield fetchUserConfigSaga();


        const initConfig = JSON.parse(`{
            "subscriptions": [],
            "eligibleOffers": [],
            "availablePlans": [],
            "planConfig": {},
            "htoken": "",
            "customerType": "",
            "xlucisveUser": false,
            "showEmailConfirmation": false,
            "updateEmailError": null,
            "updateLanguageError": null,
            "preferredPartnerPlans": [],
            "xppClaimOnThanks": null
        }`);
        const userInfo = {
            uid: '',
            msisdn: '',
            name: '',
            email: '',
            lang: tempLangFromResponse.split(','),
            dob: 0,
            gender: '',
            currSeg: '',
            new: true,
        };
        const tempUserConfig = {
            ...loginResponse.userConfig,
            ...initConfig,
            userInfo,
        };
        // for temp login userConfig in login response
        yield put({
            type: UserAction.USER_CONFIG_SUCCESS,
            userConfig: tempUserConfig,
        });
        // *** HERE IS YOUR LOGOUT SUCCESSFULLY
        yield put(logoutRequestSuccess());
        Analytics.initialize({ deviceUtil });

        if (loginAfterLogout) {
            yield put(triggerLogin({ loginTriggered: true }));
        }
        // redirect
        if (redirectUrl) {
            yield put(redirectTo({ redirectUrl }));
        }
    }
    catch (e) {
        console.error(e);
    }
    finally {
        // show Logout Toast Message
    }
}

// clear data from local but not from store
function* logoutUserSilent() {
    try {
        const deviceUtil = yield getContext('deviceUtil');
        const authInfo = yield select(getAuthConfigFromReducer);
        // disposing the token on server
        yield call(disposeAuthSession, {
            uid: authInfo.uid,
            token: authInfo.token,
            deviceUtil,
        });

        yield clearAuthDataFromLocal();

        // clear sync data
        yield put(syncContentClear({ clearLocal: false }));
        yield put(purgeLayoutData());
    }
    catch (e) {
        console.error(e);
    }
}

function* dthSubscriptionPlan(action) {
    const { key } = action;
    try {
        const deviceUtil = yield getContext('deviceUtil');
        const authInfo = yield select(getAuthConfigFromReducer);
        const { uid, token } = authInfo;
        const rechargeUrl = yield call(dthRechargeRedirection, {
            uid,
            token,
            deviceUtil,
            key,
        });
        yield put({
            type: AuthAction.DTH_SUBSCRIPTION_SUCCESS,
            rechargeUrl: rechargeUrl.url,
        });
    }

    catch (e) {
        yield put({
            type: AuthAction.ON_LOGIN_ERROR,
            errorCode: e.data ? e.data : e,
        });
    }
    finally {
        yield put(hideLoadingBar());
    }
}

function* generateHappyCode(action) {
    const deviceUtil = yield getContext('deviceUtil');
    const { uid, token } = action;

    try {
        const payload = yield call(happyCodeGeneration, {
            uid,
            token,
            deviceUtil,
        });

        yield put(generateHappyCodeSuccess(payload));
    }
    catch (e) {
        console.error('error', e);
    }
}

function* autologinWithSSO(action) {
    const { ssoToken = '', utmSource = '' } = action;
    const deviceUtil = yield getContext('deviceUtil');
    // const isIOS = deviceUtil.isIOS();
    const ssoFailedMessage = 'Oops! Something Went Wrong, Please try after sometime';
    try {
        if (ssoToken) {
            const authConfig = yield select(getAuthConfigFromReducer);
            const appId = getAppId();
            const xAtvDid = deviceUtil.getXAtvDid();
            const {
                token: tempToken = '',
                uid: tempUid = '',
            } = authConfig;
            const loginParams = {
                appId,
                uid: tempUid,
                token: tempToken,
                xAtvDid,
                ssoToken,
                utmSource,
            };
            const ssoResponse = yield call(validateSSOToken, loginParams);
            const {
                body: {
                    valid = false,
                    ivUser = '',
                } = {},
                errorMessages = [],
            } = ssoResponse;
            const code = errorMessages?.[0]?.code;
            const errorReason = errorMessages?.[0]?.message;

            if (valid) {
                const data = yield call(autoLogin, loginParams);

                yield put({
                    type: AuthAction.LOGIN_OTP_VALIDATION_SUCCESS,
                    ...data,
                });
                LocationUtil.updateViewTypeFromQuery(getWindowLocation());
                if (deviceUtil.isScopedWebview()) {
                    ScopedWebviewUtil.initScopedWebview();
                }
                if (data.success) {
                    showToastr(`Successfully logged-in with ${ivUser}`);
                }
                if (!data.success && data?.deviceResponse?.devices?.length) {
                // save devices info
                // AuthenticationUtil.setLoginDeviceSuccess(data.success);
                // AuthenticationUtil.setLoginDeviceResponse(data.deviceResponse);
                // call modalpopup
                    yield put(showModalComponentAction({
                        showModalValue: true,
                        componentNameValue: MODAL_POPUP.DEVICE_LIMIT_REACHED,
                        overrideCrossButtonValue: true,
                        payload: {
                            popUpcustomClass: 'second-pop-up',
                        },
                    }));
                    return;
                }
                // reset layout first then update user config so that user config update will actually update the ui :: reseting layout wont call update as layout container is not listening to whole reducer

                let userLang = lodashGet(data, 'userConfig.userInfo.lang', []);
                if (!userLang || userLang.length < 1) {
                    userLang = lodashGet(data, 'userConfig.userConfigResponse.ln', '').split(',');
                }
                UserInfoUtil.setUserLanguage(userLang);

                // // set user config
                yield put({
                    type: UserAction.USER_CONFIG_SUCCESS,
                    userConfig: {
                        ...data.userConfig,
                        userInfo: {
                            lang: userLang,
                        },
                    },
                });


                const { uid, token } = { ...data };

                yield call(syncContentSaga, {
                    forceSync: false,
                    playbackForced: true,
                    uid,
                    token,
                });

                yield put({
                    type: AuthAction.LOGIN_OTP_VALIDATION_SUCCESS,
                    ...data,
                });

                // yield put(syncContentInit(
                //     {
                //         forceSync: true,
                //         isDelay: false,
                //     },
                // ));

                yield put(PopupConfigFetchAction({}));
                yield put(planConfigInit());
                yield put(getCombinedPlansInit());
                const userData = yield call(getUserConfig, (deviceUtil));

                const {
                    userInfo: {
                        msisdn = '',
                    } = {},
                } = userData;
                let msisdnNo = '';
                if (msisdn && token) {
                    msisdnNo = CryptoUtil.AESDecryptMsdin({
                        key: token,
                        value: msisdn,
                    });
                }
                const { experimentDetails } = userData;
                Analytics.initialize({
                    deviceUtil,
                    experimentDetails,
                });
                BranchioUtil.setBranchIdentity(userData?.userInfo?.uid);
                yield put(userConfigSuccessAction({
                    userConfig: {
                        ...userData,
                        msisdnNo,
                    },
                }));
                yield put(purgeLayoutData());

                Crash.initialize(deviceUtil);
                regOtpResponseEvent('success');
                const programTypeTitle = getLocationPathName().split('/')[1] || '';
                successfulLoginGaEvent({
                    programTypeTitle,
                });
                const loginCount = UserInfoUtil.getSuccessfulLoginCount() + 1;
                UserInfoUtil.setSuccessfulLoginCount({ loginCount });
                UserStorageUtil.clearTempLang();
                loginCompleteEvent({
                    login_type: 'sso_with_source_number',
                    source_name: utmSource,
                    msisdn: ivUser,
                });
                yield put(isUserOnline()); // to reset error in authConfig if any
            }
            else {
                ssoFailedEvent({
                    meta: {
                        source_name: utmSource,
                        error_reason: errorReason,
                    },
                });
                ErrorHandler({
                    showToastOnly: true,
                    code: '',
                    errortitle: '',
                    error: {
                        appErrorMessage: `${code}: Something Went Wrong Redirecting to Thanks`,
                    },
                });
                LocationUtil.resetXstreamSessionOnThanks();
            }
        }
        else {
            ssoFailedEvent({
                meta: {
                    source_name: utmSource,
                    error_reason: 'sso token missing',
                },
            });
            ErrorHandler({
                showToastOnly: true,
                code: '',
                errortitle: '',
                error: {
                    appErrorMessage: `ATV_EMP:  ${ssoFailedMessage}`,
                },
            });
            LocationUtil.resetXstreamSessionOnThanks();
        }

        yield put(setAutoLoginStatus({ autoLoginInProgress: false }));
    }
    catch (e) {
        yield put(setAutoLoginStatus({ autoLoginInProgress: false }));

        LocationUtil.resetXstreamSessionOnThanks();

        // need other handling, toast?
        console.error(e);
        let error = e;
        const {
            data: {
                errortitle = '',
                errorcode = '',
            } = {},
        } = error;

        ssoFailedEvent({
            meta: {
                source_name: utmSource,
                error_reason: errortitle,
            },
        });
        if (!isOnline()) {
            error = {
                data: { errorcode: ERROR_CODES.LOCAL1003 },
            };
        }
        ErrorHandler({
            showToastOnly: true,
            code: errorcode,
            errortitle,
            error: {
                appErrorMessage: ssoFailedMessage,
            },
        });
        AuthenticationUtil.clearMsisdnDetected();
    }
    finally {
        yield put(hideLoadingBar());
    }
}

export default [
    takeFirst(AuthAction.LOGIN_OTP_GENERATE_INIT, generateOtp),
    takeFirst(AuthAction.LOGIN_OTP_VALIDATION_INIT, validateOtp),
    takeFirst(AuthAction.VALIDATE_TV_QR_CODE, validateTvLogin),
    takeFirst(AuthAction.DELETE_ACCOUNT_VALIDATION_INIT, validateDeleteAccountOtp),
    takeFirst(AuthAction.DELETE_ACCOUNT_CONFIRM_INIT, deleteAccount),
    takeFirst(AuthAction.LOGIN_REQUEST_INIT, loginGetToken),
    takeFirst(AuthAction.LOGOUT_REQUEST_INIT, logoutUser),
    takeFirst(AuthAction.LOGOUT_REQUEST_INIT_SILENT, logoutUserSilent),
    takeFirst(AuthAction.DTH_SUBSCRIPTION_INIT, dthSubscriptionPlan),
    takeFirst(AuthAction.HAPPY_CODE_GENERATE_INIT, generateHappyCode),
    takeFirst(AuthAction.DEVICE_SESSION_OUT, deviceSessionOut),
    takeFirst(AuthAction.AUTO_LOGIN, autologinWithSSO),
];
