import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { APP_TYPE, SITE_NAME, IMAGE_PATHS, DEFAULT_TITLE, DEFAULT_DESCRIPTION } from '@airtel-tv/constants/GlobalConst';
import { DEFAULT_NAV } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';

function TitleComponent(props) {
    const {
        link,
        title,
        description,
        watchActionCatalogues,
        image,
        deviceUtil,
        keywords,
        noIndexing = false,
    } = props;
    let catalogue = '';
    if (watchActionCatalogues && watchActionCatalogues.length > 0) {
        catalogue = watchActionCatalogues.map(jsonLd => (
            <script
                key={jsonLd['@type']}
                type="application/ld+json"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(jsonLd),
                }}
            />
        ));
    }
    let domainUrl = deviceUtil.getBaseUrl();
    if (domainUrl.startsWith('http://')) {
        domainUrl = domainUrl.replace('http://', 'https://');
    }
    const ogImage = image || IMAGE_PATHS[DEFAULT_NAV.HOME];
    const canonicalUrl = domainUrl + link || domainUrl;
    return (
        <>
            {
                catalogue
            }
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title || DEFAULT_TITLE}</title>
                <link
                    rel="canonical"
                    href={canonicalUrl}
                />
                <meta
                    name="description"
                    content={description || DEFAULT_DESCRIPTION}
                />
                <meta
                    name="keywords"
                    content={keywords}
                />
                {/*    Open Graph Tags   Uncomment the below once ssr is implemented      */}
                <meta
                    property="og:title"
                    content={title || DEFAULT_TITLE}
                />
                <meta
                    property="og:url"
                    content={canonicalUrl}
                />
                <meta
                    property="og:description"
                    content={description || DEFAULT_DESCRIPTION}
                />
                <meta
                    property="og:type"
                    content={APP_TYPE}
                />
                <meta
                    property="og:site_name"
                    content={SITE_NAME}
                />
                <meta
                    property="og:image"
                    content={ogImage}
                />
                <meta
                    property="og:image:alt"
                    content={description || DEFAULT_DESCRIPTION}
                />
                <meta
                    property="twitter:title"
                    content={title || DEFAULT_TITLE}
                />
                <meta
                    name="twitter:card"
                    content="summary_large_image"
                />
                <meta
                    property="twitter:url"
                    content={canonicalUrl}
                />
                <meta
                    property="twitter:description"
                    content={description || DEFAULT_DESCRIPTION}
                />
                <meta
                    property="twitter:image"
                    content={ogImage}
                />
                {noIndexing ? (
                    <meta
                        name="robots"
                        content="noindex"
                    />
                ) : null}

            </Helmet>
        </>
    );
}
TitleComponent.defaultProps = {
    link: '',
    title: '',
    description: '',
    watchActionCatalogues: [],
    image: '',
    keywords: '',
    noIndexing: false,
};

TitleComponent.propTypes = {
    link: PropTypes.string,
    title: PropTypes.string,
    keywords: PropTypes.string,
    description: PropTypes.string,
    watchActionCatalogues: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    image: PropTypes.string,
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withDeviceUtil(TitleComponent);
