/* eslint-disable jsx-a11y/alt-text */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from '../../scss/components/_cssloader.scss';

const AutoLoginLoaderComponent = (props) => {
    const {
        heading = '', 
    } = props;

    return (
        <div>
            <div>{heading}</div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="281"
                height="108"
                viewBox="0 0 281 108"
                fill="none"
            >
                <path
                    d="M27.2906 52.612C28.8081 57.8243 34.2528 58.7702 41.0006 56.417C48.3962 53.8373 56.6854 45.0225 64.9559 45.0712C70.1059 45.102 72.4164 48.6626 69.5915 55.8462C67.0058 62.4436 61.5833 68.8248 53.4136 74.3217C48.3269 77.7439 45.3329 77.9712 44.3724 76.7988C43.412 75.6265 44.4425 73.1639 48.0091 69.7827C50.1137 67.7875 52.8001 65.935 52.6523 63.7262C52.5036 61.4994 50.2564 60.6219 48.021 60.6142C38.4278 60.5825 29.9805 81.2301 43.5 80.955C60.4614 80.6098 88.0824 56.4887 79.1975 37.3929C67.457 12.7831 23.0002 37.8739 27.2906 52.612Z"
                    fill="white"
                />
                <path
                    d="M109.449 45.0208H105.976L101.366 51.6378L96.7294 45.0208H93.2688L99.5697 53.7381L92.939 62.9579H96.4124L101.366 55.9307L106.318 62.9579H109.793L103.162 53.7381L109.449 45.0208Z"
                    fill="white"
                />
                <path
                    d="M116.833 55.4292C114.97 54.9798 114.033 54.7157 114.033 53.7519C114.033 52.9333 114.878 52.4044 116.133 52.4044C117.626 52.4044 118.708 53.1572 119.395 54.1347L121.152 52.6291C120.307 51.2423 118.497 50.0135 116.133 50.0135C113.544 50.0135 111.444 51.4927 111.444 53.7912C111.444 56.3008 113.385 57.1595 116.106 57.7671C117.969 58.19 118.749 58.4806 118.749 59.4179C118.749 60.2767 117.732 60.8184 116.371 60.8184C114.76 60.8184 113.545 59.9597 112.831 58.7181L110.89 60.2895C111.867 62.0856 113.901 63.2221 116.371 63.2221C119.066 63.2221 121.391 61.7293 121.391 59.4179C121.39 57.0006 119.594 56.1026 116.833 55.4292Z"
                    fill="white"
                />
                <path
                    d="M129.896 60.8578C128.601 60.8578 127.822 60.1845 127.822 58.8369V52.4967H131.825V50.2776H127.796V46.2095H125.247V50.2776H122.948V52.4967H125.207V59.154C125.207 61.7294 126.871 63.2221 129.526 63.2221C130.543 63.2221 131.666 62.9974 132.3 62.6676L131.956 60.422C131.336 60.686 130.504 60.8578 129.896 60.8578Z"
                    fill="white"
                />
                <path
                    d="M137.398 52.0079V50.2776H134.77V62.958H137.398V56.0504C137.398 53.1845 139.221 52.4445 140.621 52.4445C141.453 52.4445 142.022 52.6821 142.167 52.7351L142.445 50.3314C142.445 50.3314 141.692 50.0144 140.702 50.0144C139.313 50.0136 138.111 50.6211 137.398 52.0079Z"
                    fill="white"
                />
                <path
                    d="M150.052 50.0135C146.208 50.0135 143.5 52.853 143.5 56.6306C143.5 60.3946 146.314 63.2212 150.144 63.2212C152.389 63.2212 154.701 62.1121 155.876 59.9981L153.948 58.5976C152.997 60.0904 151.729 60.7637 150.144 60.7637C147.951 60.7637 146.327 59.271 146.208 57.3689H156.048C156.128 56.9596 156.154 56.5503 156.154 56.1538C156.154 52.7607 153.75 50.0135 150.052 50.0135ZM146.274 55.4156C146.604 53.6323 148.084 52.3779 150.025 52.3779C151.848 52.3779 153.34 53.6323 153.498 55.4156H146.274Z"
                    fill="white"
                />
                <path
                    d="M164 50.0135C161.689 50.0135 159.654 51.3474 158.677 52.5889L160.328 54.227C161.081 53.3682 162.388 52.4702 163.961 52.4702C165.928 52.4702 167.21 53.6588 167.21 55.2438V55.9308C166.629 55.6531 165.533 55.3361 164.014 55.3361C160.685 55.3361 158.413 56.9741 158.413 59.3513C158.413 61.8344 160.567 63.2212 163.314 63.2212C165.018 63.2212 166.484 62.5077 167.263 61.3191V62.9572H169.826V55.0191C169.825 51.9421 167.566 50.0135 164 50.0135ZM163.696 61.0825C162.203 61.0825 161.054 60.4091 161.054 59.2599C161.054 57.8859 162.428 57.3313 164.079 57.3313C165.453 57.3313 166.668 57.6611 167.208 57.9388C167.209 59.6691 165.757 61.0825 163.696 61.0825Z"
                    fill="white"
                />
                <path
                    d="M189.189 50.0135C187.009 50.0135 185.425 51.1363 184.658 52.3779C183.852 50.9116 182.32 50.0135 180.141 50.0135C177.909 50.0135 176.706 51.2286 176.297 51.8763V50.2784H173.669V62.958H176.297V55.812C176.297 53.7117 177.843 52.4967 179.731 52.4967C181.594 52.4967 182.716 53.7117 182.716 55.812V62.958H185.344V55.654C185.41 53.6596 186.929 52.4975 188.778 52.4975C190.641 52.4975 191.763 53.7126 191.763 55.8129V62.9589H194.391V55.2583C194.392 52.1797 192.57 50.0135 189.189 50.0135Z"
                    fill="white"
                />
                <path
                    d="M212.233 45.0072H204.873V62.9042H207.642V56.2308H212.232C215.925 56.2308 218.311 53.8963 218.311 50.6126C218.313 47.2895 215.926 45.0072 212.233 45.0072ZM212.114 53.699H207.643V47.5262H212.114C214.29 47.5262 215.424 48.8712 215.424 50.6126C215.425 52.3275 214.29 53.699 212.114 53.699Z"
                    fill="white"
                />
                <path
                    d="M223.655 44.3347H221.043V62.9042H223.655V44.3347Z"
                    fill="white"
                />
                <path
                    d="M232.398 49.9794C230.09 49.9794 228.059 51.3115 227.083 52.5514L228.732 54.1868C229.483 53.3298 230.789 52.4326 232.359 52.4326C234.324 52.4326 235.603 53.6195 235.603 55.2019V55.8881C235.023 55.6112 233.928 55.2942 232.412 55.2942C229.088 55.2942 226.82 56.9297 226.82 59.3034C226.82 61.7831 228.97 63.1674 231.713 63.1674C233.414 63.1674 234.878 62.4556 235.656 61.2679V62.9033H238.215V54.9772C238.215 51.9054 235.959 49.9794 232.398 49.9794ZM232.095 61.032C230.605 61.032 229.457 60.3596 229.457 59.212C229.457 57.8406 230.828 57.2869 232.478 57.2869C233.849 57.2869 235.062 57.6167 235.603 57.8935C235.603 59.6204 234.152 61.032 232.095 61.032Z"
                    fill="white"
                />
                <path
                    d="M250.81 50.2434L246.893 60.2143L242.949 50.2434H240.074L245.534 63.0888L245.257 63.8142C244.69 65.2916 244.005 66.1487 242.303 66.1487C241.644 66.1487 240.958 65.9769 240.602 65.8188L240.325 67.9815C240.549 68.1003 241.301 68.4429 242.422 68.4429C245.508 68.4429 246.603 66.8604 247.618 64.5123L253.685 50.2426H250.81V50.2434Z"
                    fill="white"
                />
            </svg>
        </div>
    );
};

export default withStyles(styles)(AutoLoginLoaderComponent);
